import { publish } from "../helper/pubsub";
import { PUB_SUB_EVENTS } from "../helper/constants";

class VariantSelects extends HTMLElement {
    constructor() {
      super();
      this.addEventListener('change', this.onVariantChange);
      this.boundToggleBIS = this.toggleBIS.bind(this);
    }
  
    onVariantChange() {
      this.updateOptions();
      this.updateMasterId();
      this.toggleAddButton(true, '', false);
      this.updatePickupAvailability();
      this.removeErrorMessage();
      this.updateVariantInput();
      this.updateVariantStatuses();
  
      if (!this.currentVariant) {
        this.toggleAddButton(false, '', true);
        this.setUnavailable();
      } else {
        this.updateURL();
        this.updateVariantInput();
        this.renderProductInfo();
        this.updateShareUrl();
        this.updateOptions();
        //this.updateVariantTitle();
      }
    }
  
    updateOptions() {
      this.options = Array.from(this.querySelectorAll('select'), (select) => select.value);
    }
  
    updateMasterId() {
      this.currentVariant = this.getVariantData().find((variant) => {
        return !variant.options
          .map((option, index) => {
            return this.options[index].toLowerCase() === option.toLowerCase();
          })
          .includes(false);
      });
    }
  
    updateURL() {
      if (!this.currentVariant || this.dataset.updateUrl === 'false') return;
      window.history.replaceState({}, '', `${this.dataset.url}?variant=${this.currentVariant.id}`);
    }
  
    updateShareUrl() {
      const shareButton = document.getElementById(`Share-${this.dataset.section}`);
      if (!shareButton || !shareButton.updateUrl) return;
      shareButton.updateUrl(`${window.shopUrl}${this.dataset.url}?variant=${this.currentVariant.id}`);
    }
  
    updateVariantInput() {
      const productForm = this.closest(`form`);
        const input = productForm.querySelector('input[name="id"]');
        input.value = this.currentVariant.id;
        input.dispatchEvent(new Event('change', { bubbles: true }));
    }
  
    updateVariantStatuses() {
      const selectedOptionOneVariants = this.variantData.filter(
        (variant) => this.querySelector(':checked').value.toLowerCase() === variant.option1.toLowerCase()
      );
      const inputWrappers = [...this.querySelectorAll('.product-form__input')];
      inputWrappers.forEach((option, index) => {
        if (index === 0) return;
        const optionInputs = [...option.querySelectorAll('input[type="radio"], option')];
        const previousOptionSelected = inputWrappers[index - 1].querySelector(':checked').value;
        const availableOptionInputsValue = selectedOptionOneVariants
          .filter((variant) => variant.available && variant[`option${index}`].toLowerCase() === previousOptionSelected.toLowerCase())
          .map((variantOption) => variantOption[`option${index + 1}`]);
        this.setInputAvailability(optionInputs, availableOptionInputsValue);
      });
    }
  
    setInputAvailability(listOfOptions, listOfAvailableOptions) {
      listOfOptions.forEach((input) => {
        if (listOfAvailableOptions.includes(input.getAttribute('value').toLowerCase())) {
          input.innerText = input.getAttribute('value');
        } else {
          input.innerText = window.variantStrings.unavailable_with_option.replace('[value]', input.getAttribute('value'));
        }
      });
    }
  
    updatePickupAvailability() {
      const pickUpAvailability = document.querySelector('pickup-availability');
      if (!pickUpAvailability) return;
  
      if (this.currentVariant && this.currentVariant.available) {
        pickUpAvailability.fetchAvailability(this.currentVariant.id);
      } else {
        pickUpAvailability.removeAttribute('available');
        pickUpAvailability.innerHTML = '';
      }
    }
  
    removeErrorMessage() {
      const section = this.closest('section');
      if (!section) return;
  
      const productForm = section.querySelector('product-form');
      if (productForm) productForm.handleErrorMessage();
    }
  
    renderProductInfo() {
      const requestedVariantId = this.currentVariant.id;
      const sectionId = this.dataset.originalSection ? this.dataset.originalSection : this.dataset.section;
  
      fetch(
        `${this.dataset.url}?variant=${requestedVariantId}&section_id=${
          this.dataset.originalSection ? this.dataset.originalSection : this.dataset.section
        }`
      )
        .then((response) => response.text())
        .then((responseText) => {
          // prevent unnecessary ui changes from abandoned selections
          if (this.currentVariant.id !== requestedVariantId) return;
  
          const html = new DOMParser().parseFromString(responseText, 'text/html');
          const destination = document.getElementById(`price-${this.dataset.section}`);
          const source = html.getElementById(
            `price-${this.dataset.originalSection ? this.dataset.originalSection : this.dataset.section}`
          );
          const skuSource = html.getElementById(
            `Sku-${this.dataset.originalSection ? this.dataset.originalSection : this.dataset.section}`
          );
          const skuDestination = document.getElementById(`Sku-${this.dataset.section}`);
          const inventorySource = html.getElementById(
            `Inventory-${this.dataset.originalSection ? this.dataset.originalSection : this.dataset.section}`
          );
          const inventoryDestination = document.getElementById(`Inventory-${this.dataset.section}`);
  
          if (source && destination) destination.innerHTML = source.innerHTML;
          if (inventorySource && inventoryDestination) inventoryDestination.innerHTML = inventorySource.innerHTML;
          if (skuSource && skuDestination) {
            skuDestination.innerHTML = skuSource.innerHTML;
            skuDestination.classList.toggle('visibility-hidden', skuSource.classList.contains('visibility-hidden'));
          }
  
          const price = document.getElementById(`price-${this.dataset.section}`);
  
          if (price) price.classList.remove('visibility-hidden');
  
          if (inventoryDestination)
            inventoryDestination.classList.toggle('visibility-hidden', inventorySource.innerText === '');
  
          const addButtonUpdated = html.getElementById(`ProductSubmitButton-${sectionId}`);

          this.toggleAddButton(
            addButtonUpdated ? addButtonUpdated.hasAttribute('sold-out') : this.querySelector('button[type="submit"]'),
            window.variantStrings.notify
          );

          //const BISLabel = document.querySelector('#selected-variant').textContent = this.currentVariant.title

  
          publish(PUB_SUB_EVENTS.variantChange, {
            data: {
              sectionId,
              html,
              variant: this.currentVariant,
            },
          });
        });
    }

    toggleBIS(event) {
      const productFormId = this.querySelector('input').getAttribute('form');
      const productForm = document.querySelector(`#${productFormId}`)
        event.preventDefault();
        const productIdInput = productForm.querySelector('.product-variant-id');
        //const productTitle = productForm.querySelector('.js-title').innerHTML;
        if (productIdInput) {
            //document.getElementById("product-name").textContent = `${productTitle}`;
            document.querySelector("klaviyo-bis").classList.remove('hidden');
            document.querySelector("klaviyo-bis").classList.add('flex');
        }
    }
  
    toggleAddButton(disable = true, text, modifyClass = true, preorder = false) {
      const productFormId = this.querySelector('input').getAttribute('form');
      const productForm = document.querySelector(`#${productFormId}`)

      if (!productForm) return;
      const addButton = productForm.querySelector('[name="add"]');
      const addButtonText = productForm.querySelector('[name="add"] > span');
      if (!addButton) return;

      preorder = addButton.hasAttribute('preorder');

      if (disable) {
        addButton.setAttribute('sold-out', 'true');
        if (text) {
          addButtonText.textContent = text;
          addButton.classList.add('klaviyo-bis-trigger');
          addButton.addEventListener("click", this.boundToggleBIS);
        }
      } else {
        addButton.removeAttribute('sold-out');
        addButton.classList.remove('klaviyo-bis-trigger');
        addButton.removeEventListener("click", this.boundToggleBIS);
        if(preorder) {
          addButtonText.textContent = window.variantStrings.preOrder;
        } else {
          addButtonText.textContent = window.variantStrings.addToCart;
        }
        
      }
  
      if (!modifyClass) return;
    }
  
    setUnavailable() {
      const button = document.getElementById(`product-form-${this.dataset.section}`);
      const addButton = button.querySelector('[name="add"]');
      const addButtonText = button.querySelector('[name="add"] > span');
      const price = document.getElementById(`price-${this.dataset.section}`);
      const inventory = document.getElementById(`Inventory-${this.dataset.section}`);
      const sku = document.getElementById(`Sku-${this.dataset.section}`);
  
      if (!addButton) return;
      addButtonText.textContent = window.variantStrings.unavailable;
      if (price) price.classList.add('visibility-hidden');
      if (inventory) inventory.classList.add('visibility-hidden');
      if (sku) sku.classList.add('visibility-hidden');
    }
  
    getVariantData() {
      this.variantData = JSON.parse(this.querySelector('[type="application/json"]').textContent);
      return this.variantData;
    }
  }
  
  customElements.define('variant-selects', VariantSelects);
  
  class VariantRadios extends VariantSelects {
    constructor() {
      super();
    }
  
    setInputAvailability(listOfOptions, listOfAvailableOptions) {
      listOfOptions.forEach((input) => {
        if (listOfAvailableOptions.includes(input.getAttribute('value'))) {
          input.classList.remove('disabled');
          //this.toggleAddButton(false, '', false);
        } else {
          input.classList.add('disabled');
          //this.toggleAddButton(true, '', true);
        }
      });
    }
  
    updateOptions() {
      const fieldsets = Array.from(this.querySelectorAll('fieldset'));
      this.options = fieldsets.map((fieldset) => {
        return Array.from(fieldset.querySelectorAll('input')).find((radio) => radio.checked).value;
      });
    }
  }

  customElements.define('variant-radios', VariantRadios);